import { useSimulateWithdrawLPDfi, useWithdrawableAmount, useModal } from '@apps-orangefi/hooks'
import { BN } from '@apps-orangefi/lib'
import { needReloadAtom, vaultDecimalsAtom } from '@apps-orangefi/lib/store'
import { UserPosition, Token } from '@apps-orangefi/lib/types'
import { convertToPoolToken } from '@apps-orangefi/lib/utils'
import { WithdrawForm, WalletProps } from '@apps-orangefi/ui/molecules/strategy'
import { MODAL_TYPES } from '@apps-orangefi/ui/organisms/modals'
import { useAtomValue, useSetAtom } from 'jotai'
import { useCallback, useEffect, useState } from 'react'
import { useAccount } from 'wagmi'

export type WithdrawContainerProps = {
  vaultAddress: AddressType | undefined
  positionManagerAddress: AddressType | undefined
  handlerAddress: AddressType | undefined
  userPosition: UserPosition | undefined
  chainId: number
  poolTick: {
    token0: Token | undefined
    token1: Token | undefined
    tick: number | undefined
  }
  vaultBaseToken: Token | undefined
  ethPriceUSD: BN | undefined
  tokenSymbol: string | undefined
  isClosed: boolean // archived vault
}

type Props = { withdraw: WithdrawContainerProps; wallet: WalletProps }

export const WithdrawContainer = ({ withdraw, wallet }: Props) => {
  const { address: account } = useAccount()
  const vaultDecimals = useAtomValue(vaultDecimalsAtom)
  const [estimateLPAmount, setEstimateLPAmount] = useState(new BN(0))
  const [statusKey, setStatusKey] = useState<string>('WITHDRAW')
  const [isAllStepsEnd, setIsAllStepsEnd] = useState<boolean>(false)
  const setNeedReload = useSetAtom(needReloadAtom)

  const simulateProps = useSimulateWithdrawLPDfi(
    withdraw.vaultAddress,
    account,
    estimateLPAmount,
    vaultDecimals,
    withdraw.chainId,
    {
      token0: withdraw.poolTick.token0 ? convertToPoolToken(withdraw.poolTick.token0) : undefined,
      token1: withdraw.poolTick.token1 ? convertToPoolToken(withdraw.poolTick.token1) : undefined,
      tick: withdraw.poolTick.tick,
    },
    withdraw.vaultBaseToken,
    withdraw.ethPriceUSD
  )

  const { withdrawableAmount, withdrawableLpAmount, onChangeRateLP } = useWithdrawableAmount(
    withdraw.userPosition?.myPosition?.position,
    withdraw.userPosition?.myPosition?.lpBalance
  )

  useEffect(() => {
    if (!withdrawableLpAmount.eq(estimateLPAmount)) {
      setEstimateLPAmount(withdrawableLpAmount)
    }
  }, [withdrawableLpAmount])

  const { showModal, hideModal } = useModal()

  const onWithdrawAndReserve = useCallback(() => {
    showModal({
      modalType: MODAL_TYPES.WithdrawAndReserveModalContainer,
      modalProps: {
        vaultAddress: withdraw.vaultAddress,
        positionManagerAddress: withdraw.positionManagerAddress,
        handlerAddress: withdraw.handlerAddress,
        withdrawableLpAmount,
        simulateProps,
        handleClose: () => {
          setNeedReload(true)
          hideModal()
        },
        handleCallback: setIsAllStepsEnd,
      },
    })
  }, [
    withdraw.vaultAddress,
    withdraw.positionManagerAddress,
    withdraw.handlerAddress,
    withdrawableLpAmount,
    simulateProps,
  ])

  const onSubmitAction = useCallback(() => {
    if (!withdrawableAmount.isZero() && !simulateProps.resultWithdrawSimulation) {
      simulateProps.simulateWithdrawLPDfi()
      return
    } else if (simulateProps.isSimulating || simulateProps.isLpPositionFetching) {
      return
    }

    if (!withdrawableAmount.isZero()) {
      onWithdrawAndReserve()
      return
    }
  }, [simulateProps, withdrawableAmount, onWithdrawAndReserve])

  const onChangeRate = useCallback(
    (rate: BN) => {
      onChangeRateLP(rate)
      simulateProps.resetResultWithdrawSimulation()
    },
    [withdrawableAmount, onChangeRateLP, simulateProps.resetResultWithdrawSimulation]
  )

  const isSimulateAvailable =
    estimateLPAmount.gt(0) &&
    simulateProps &&
    !simulateProps.isSimulating &&
    !simulateProps.isLpPositionFetching

  const withdrawProps = {
    withdrawableAmount,
    onChangeRate,
    onWithdraw: onSubmitAction,
    simulateProps,
    tokenSymbol: withdraw.tokenSymbol,
    isClosed: withdraw.isClosed, // vault archived
    statusKey,
    isAllStepsEnd,
    isFormAvailable: isSimulateAvailable,
  }

  return <WithdrawForm withdraw={withdrawProps} wallet={wallet} />
}
