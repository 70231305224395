import { useWithdrawReservedLPForm } from '@apps-orangefi/hooks'
import { BN } from '@apps-orangefi/lib'
import {
  TotalWithdrawableReserveLiquidity,
  WithdrawableReserveLiquidity,
} from '@apps-orangefi/lib/types'
import { RedeemReservedLiquidity } from '@apps-orangefi/ui/molecules/lpdfi'
import { WalletProps } from '@apps-orangefi/ui/molecules/strategy'
import { V2RedeemReservedLiquidity } from '@apps-orangefi/ui/molecules/v2'
import { ReserveLiquidityParam } from '@apps-orangefi/wagmi/hooks'
import { useEffect } from 'react'
import { UseQueryExecute } from 'urql'

export type RedeemReservedLiquidityContainerProps = {
  handlerAddress: AddressType | undefined
  positionManagerAddress: AddressType | undefined
  chainId: number
  reservedLPStatus: {
    withdrawReservedLiquidityParams: ReserveLiquidityParam[]
    fetching: boolean
    refetch: UseQueryExecute
    withdrawableReserveLiquidities: WithdrawableReserveLiquidity[]
    totalWithdrawableReserveLiquidity: TotalWithdrawableReserveLiquidity
    totalAmountUSD: BN
  }
}

type Props = {
  redeemReservedLP: RedeemReservedLiquidityContainerProps
  wallet: WalletProps
  version: 'v1' | 'v2'
  className?: string
}

export const RedeemReservedLiquidityContainer = ({
  redeemReservedLP,
  wallet,
  version,
  className,
}: Props) => {
  const { onWithdrawReservedLP } = useWithdrawReservedLPForm({
    handlerAddress: redeemReservedLP.handlerAddress,
    positionManagerAddress: redeemReservedLP.positionManagerAddress,
    withdrawReservedLiquidityParams:
      redeemReservedLP.reservedLPStatus.withdrawReservedLiquidityParams,
    refetch: redeemReservedLP.reservedLPStatus.refetch,
  })

  useEffect(() => {
    redeemReservedLP.reservedLPStatus.refetch()
  }, [])
  // }, [redeemReservedLP.reservedLPStatus.refetch])

  return (
    <div className={className}>
      {version === 'v1' ? (
        <RedeemReservedLiquidity
          reservedLPStatus={redeemReservedLP.reservedLPStatus}
          onWithdrawReservedLP={onWithdrawReservedLP}
          {...wallet}
        />
      ) : (
        <V2RedeemReservedLiquidity
          reservedLPStatus={redeemReservedLP.reservedLPStatus}
          onWithdrawReservedLP={onWithdrawReservedLP}
          {...wallet}
        />
      )}
    </div>
  )
}
